import { toast } from "react-toastify";

export default class MessageService {
    success(message) {
        toast(message, {
            type: "success"
        });
    }

    error(message) {
        toast(message, {
            type: "error"
        });
    }
}