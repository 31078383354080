import MessageService from "../../services/MessageService";

export default (result) => {
    let messageService = new MessageService();
    const showFolderNavigationError = (code) => {
        switch (code) {
            case 2:
                messageService.error("Ruta incorrecta");
                break;
            case 3:
                messageService.error("Sin Permisos");
                break;
        }
    }
    return !Array.isArray(result) && showFolderNavigationError(result.code)
}