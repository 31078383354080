import selectPath from "../selectPath";

export default (path) => new Promise((resolve, reject) => {
    const routes = selectPath();
    let headers = new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json"
    });

    let request = new Request(routes.files.base, {
        method: "POST",
        headers,
        body: JSON.stringify({ path }),
        credentials: "include"
    });

    fetch(request)
        .then(res => res.json())
        .then(data => resolve(data))
        .catch(err => resolve(err));
});