import React, { useContext } from 'react';
import UserState from './contexts/UserContext/UserState';
import FileState from './contexts/FileContext/FileState';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import MainScreen from './screens/MainScreen/MainScreen';
import ExplorerScreen from './screens/ExplorerScreen/ExplorerScreen';
import PermissionScreen from './screens/PermissionScreen/PermissionScreen';

function App() {
  return (
    <>
      <UserState>
        <FileState>
          <Router>
            <Routes>
              <Route path="/" element={<LoginScreen />} />
              <Route path="/main" element={<MainScreen />} />
              <Route path="/permissions" element={<PermissionScreen />} />
              <Route path="/explorer" element={<FileState><ExplorerScreen /></FileState>}>
                <Route path=":idUser" element={<FileState><ExplorerScreen /></FileState>} />
              </Route>
            </Routes>
          </Router>
          <ToastContainer />
        </FileState>
      </UserState>
    </>
  );
}

export default App;
