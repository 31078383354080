import GetByFolder from "../services/permissions/GetByFolder"
import GetFolderByUser from "../services/permissions/GetFolderByUser"
import PostPermissions from "../services/permissions/PostPermissions"

const usePermissions = () => {

    const getPermissionsUserByFolder = (folder) => GetByFolder(folder)

    const getPermissionsFolderByUser = (user_id) => GetFolderByUser(user_id)

    const registerPermissions = (data) => PostPermissions(data)

    return { getPermissionsUserByFolder, registerPermissions, getPermissionsFolderByUser }
}

export default usePermissions