import React from "react";
import {
    folderBackIco, folderIco, docIco, docxIco, jpegIco, jpgIco,
    odpIco, odsIco, odtIco, pdfIco, pngIco, pptIco, pptxIco, txtIco, xlsIco, xlsxIco,
    rarIco, zipIco
} from "../Constants/ImgPath";

export default ({ name, children, onClick }) => {
    let ico = folderIco;

    let iconsStyles = {
        width: "4rem"
    }

    const iconsByExtensions = {
        doc: docIco,
        docx: docxIco,
        odt: odtIco,
        jpeg: jpegIco,
        jpg: jpgIco,
        png: pngIco,
        txt: txtIco,
        pdf: pdfIco,
        ppt: pptIco,
        pptx: pptxIco,
        odp: odpIco,
        xls: xlsIco,
        xlsx: xlsxIco,
        ods: odsIco,
        zip: zipIco,
        rar: rarIco,
    }


    if (name) {
        let splittedName = name.split(".");
        let extension = splittedName[splittedName.length - 1];

        if (extension && splittedName.length > 1) {
            ico = iconsByExtensions[extension];
            iconsStyles.width = extension === 'rar' || extension ==='zip' ? "5rem" :"4.5rem";
        }
        return (
            <div className="card text-center mt-2" style={{ borderLeftWidth: 5, borderStyle: "solid", borderLeftColor: "#0A71FA" }}>
                <div className="card-body" onClick={onClick} style={{ cursor: "pointer", height: 150, zIndex:1 }} >
                    <div className="d-flex justify-content-center">
                        <h5 className="card-title text-truncate mb-3" style={{ textTransform: "capitalize", flex: "1", marginBottom: 0, textAlign: "left" }}>{name}</h5>
                        {children}
                    </div>
                    <img src={ico} style={iconsStyles}/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="card text-center mt-2">
                <div className="card-body" style={{ cursor: "pointer", height: 150 }}>
                    <h5 className="card-title mb-3" style={{ textTransform: "capitalize", display: "block", marginBottom: 0, textAlign: "left"  }}>Ir atrás</h5>
                    <img src={folderBackIco} style={iconsStyles} />
                </div>
            </div>
        );
    }
}
