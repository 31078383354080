import Folder from "../Folder";
import DeleteButtonKanban from "./DeleteButtonKanban";
import DownloadButtonKanban from "./DownloadButtonKanban";

export default ({ visible, data, onClickElement, isComun = false, otherOnclick, visibleDownload = false, onClickDownload, visibleDelete = false, onClickDelete }) => (
    visible && (
        data.map((element, index) => (
            <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <Folder name={element.name} onClick={(e) => element.directory ? onClickElement(element, isComun) : otherOnclick(e, element, isComun)} >
                    <DownloadButtonKanban visible={visibleDownload} isComun={isComun} onClickDownload={onClickDownload} element={element} />
                    <DeleteButtonKanban visible={visibleDelete} isComun={isComun} onClickDelete={onClickDelete} element={element} />
                </Folder>
            </div>
        )))
)