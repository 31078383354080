import { useRef, useState } from "react";
import BootstrapModal from "../../components/BootstrapModal";
import Menu from "../../components/Menu/Menu"
import { folderIco } from "../../Constants/ImgPath";
import usePermissions from "../../hooks/usePermissions";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton/CustomButton";

const PermissionScreenUI = ({ userData, contentsComun, userList }) => {

    //#region 'USE REF'
    const refUserList = useRef()
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { getPermissionsUserByFolder, registerPermissions } = usePermissions()
    //#endregion

    //#region 'USE STATES'
    const [selectFolder, setSelectFolder] = useState('')
    const [searchUsers, setSearchUsers] = useState('')
    const [searchContent, setSearchContent] = useState('')
    const [contentPermissions, setContentPermissions] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const doFilterUser = (value) => {
        if (!searchUsers.trim()) {
            return value
        } else if (value && value.toLowerCase().includes(searchUsers.toLocaleLowerCase())) {
            return value
        }
    }

    const doFilterContent = (value) => {
        if (!searchContent.trim()) {
            return value
        } else if (value && value.toLowerCase().includes(searchContent.toLocaleLowerCase())) {
            return value
        }
    }

    const handleAssignedUsers = async () => {
        let checkDelete = contentPermissions.filter(el => !el.allow && el?.id)
        let checkAdd = contentPermissions.filter(el => el.allow && !el?.id)
        if (checkDelete.length > 0 || checkAdd.length > 0) {
            const result = await registerPermissions(contentPermissions)
            if (result.type === 'success') {
                toast.success('Se ha registrado correctamente.')
            } else {
                toast.error('Error al registrar.')
            }
            refUserList.current.hide()
        }
    }

    const handleSelectUser = (e, user_id) => {
        let data = [...contentPermissions]
        if (data.some(el => el.user_id === user_id)) {
            data.map(el => el.user_id === user_id && (el.allow = !el.allow))
        } else {
            data.push({ folder: selectFolder, user_id, allow: e.target.checked, create_uid: userData.Id })
        }
        setContentPermissions(data)
    }

    const handleGetPermissions = async (folder) => {
        let result = await getPermissionsUserByFolder(folder)
        if (Array.isArray(result)) {
            let data = result
            data.map(el => el.allow = true)
            setContentPermissions(data)
        }
    }

    //#endregion


    //#region 'COMPONENTS'
    const StructureTable = ({ itemtrHead, itemBody }) => (
        <table className="table table-striped mt-2">
            <thead>
                {itemtrHead}
            </thead>
            <tbody>
                {itemBody}
            </tbody>
        </table>
    )

    const ItemHeadContentComun = () => (
        <tr>
            <th style={{ width: 30 }}> </th>
            <th>Nombre</th>
            <th style={{ width: 30 }}> </th>
            <th style={{ width: 30 }}> </th>
        </tr>
    )

    const ItemBodyContentComun = () => (
        contentsComun.filter(el => doFilterContent(el.name)).map((element) => (
            <tr>
                <td><img src={folderIco} style={{ width: "2rem" }} /></td>
                <td colSpan={'100%'}>{element.name}</td>
                <td style={{ width: 30 }}>
                    <CustomButton text={"Asignar"} onClick={() => { setSelectFolder(element.name); handleGetPermissions(element.name); refUserList.current.show() }} />
                </td>
            </tr>
        ))
    )

    const ItemHeadUsers = () => (
        <tr>
            <th style={{ width: 30 }}> </th>
            <th>Nombre</th>
        </tr>
    )

    const ItemsUserListAssign = () => (
        userList.filter((el) => doFilterUser(el.Name)).sort((a, b) => (contentPermissions?.find(el => el.user_id === a.Id)?.allow === contentPermissions?.find(el => el.user_id === b.Id)?.allow) ? 0 : contentPermissions?.find(el => el.user_id === a.Id)?.allow ? -1 : 1).map((element) => (
            <tr>
                <td><input type="checkbox" onChange={(e) => handleSelectUser(e, element.Id)} checked={contentPermissions?.find(el => el.user_id === element.Id)?.allow} /></td>
                <td colSpan={'100%'}>{element.Name}</td>
            </tr>
        ))
    )

    const UserListAssign = (
        <div>
            <input type="search" name="searchUser"
                autoComplete="off"
                onChange={(e) => setSearchUsers(e.target.value)}
                className="form-control mb-2"
                value={searchUsers}
                placeholder="Buscar..."
            />
            <StructureTable
                itemtrHead={<ItemHeadUsers />}
                itemBody={<ItemsUserListAssign />}
            />
        </div>
    )

    //#endregion

    return (
        <div>
            <Menu userData={userData} />
            <BootstrapModal ref={refUserList}
                name="UserListModal"
                title="Lista de usuarios"
                confirmLabel="Guardar"
                confirmAction={handleAssignedUsers}
                confirmDisplay={true}
                hideAction={() => refUserList.current.hide()}
            >
                {UserListAssign}
            </BootstrapModal>
            <div style={{paddingLeft:10, paddingRight:10}}>
                <input type="search" name="searchContent"
                    autoComplete="off"
                    onChange={(e) => setSearchContent(e.target.value)}
                    className="form-control mb-2"
                    value={searchContent}
                    placeholder="Buscar..."
                />
            </div>
            <StructureTable
                itemtrHead={<ItemHeadContentComun />}
                itemBody={<ItemBodyContentComun />}
            />
        </div>

    )
}

export default PermissionScreenUI