//#region IMPORTS
import React from "react";
import ClientForm from "../../components/ClientForm/ClientForm";
import Menu from "../../components/Menu/Menu";
import BootstrapModal from "../../components/BootstrapModal";
import Folder from "../../components/Folder";
import CreateFolderForm from "../../components/CreateFolderForm/CreateFolderForm";
import CustomButton from "../../components/CustomButton/CustomButton";
import UploadFilesForm from "../../components/UploadFilesForm/UploadFilesForm";
import { ViewType } from "../../components/ViewType/ViewType";
import {
    folderBackIco, folderIco, docIco, docxIco, jpegIco, jpgIco,
    odpIco, odsIco, odtIco, pdfIco, pngIco, pptIco, pptxIco, txtIco, xlsIco, xlsxIco,
    rarIco, zipIco
} from "../../Constants/ImgPath";
//#endregion

const MainScreenUI = ({ clientFormModalRef, userFormValues, setUserFormValues, clients, exploreClientContents, getClientFormInitialValues,
    submitClientFormCreate, submitClientFormUpdate, isEditingClientForm, setIsEditingClientForm,
    clickElement, currentPath, goBack, doFilter, doFilterComun, deleteElementModalRef, uploadModalRef,
    showFolderComun = { get: undefined, set: undefined },
    search = { get: undefined, set: undefined },
    paramsInit = { isUsers: false, isFolder: false },
    comun = { folder: undefined, setFolder: undefined, contentFolder: undefined, setContentFolder: undefined, visible: false, setVisible: undefined },
    submitFormCreateFolder, userData, deleteTarget,
    setDeleteTarget, deleteElement, createFolderModalRef,
    views = { type: {}, funcKanban: undefined, funcList: undefined },
    submitFormUploadFiles, folderNameForm, handleDownloadFile, contentsComun, currentPathComun, breadCumb, handleBreadCumb
}) => {

    const submitCreateClientModal = "submitCreateClientModal";
    const submitCreateFolderModal = "submitCreateFolderModal";
    const submitUploadFilesModal = "submitUploadFilesModal";

    const iconsByExtensions = {
        doc: docIco,
        docx: docxIco,
        odt: odtIco,
        jpeg: jpegIco,
        jpg: jpgIco,
        png: pngIco,
        txt: txtIco,
        pdf: pdfIco,
        ppt: pptIco,
        pptx: pptxIco,
        odp: odpIco,
        xls: xlsIco,
        xlsx: xlsxIco,
        ods: odsIco,
        zip: zipIco,
        rar: rarIco,
    }

    const resetClientForm = () => {
        setIsEditingClientForm(false);
        getClientFormInitialValues();
    }

    const clearCreateFolderForm = () => document.getElementById("name").value = "";

    const deleteElementModalForm = () => document.getElementById("files").value = "";

    const getCurrentLevel = () => {
        let splittedCurrentPath = currentPathComun?.split("");
        splittedCurrentPath?.shift();
        splittedCurrentPath?.pop();
        return splittedCurrentPath?.length;
    }

    const handleEditFomUser = (client) => {
        client.Password = "";
        client.PasswordConfirmation = "";
        setUserFormValues(client);
        clientFormModalRef.current.show();
    }

    const handleCreateUser = () => clientFormModalRef.current.show()

    const handleGoBack = () => {
        goBack()
        showFolderComun.set(false)
    }

    const handleDeleteItem = (e, element) => {
        e.stopPropagation()
        setDeleteTarget(element);
        deleteElementModalRef.current.show()
    }

    const handleNavigateFolder = (element) => element.directory && clickElement(element, true, userData.Id)

    const ButtonsAction = ([
        paramsInit.isUsers && (<CustomButton text={"Añadir usuario"} icon={"fa-solid fa-user-plus"} onClick={handleCreateUser} />),
        getCurrentLevel() > 1 && ([
            <CustomButton text={"Crear carpeta"} icon={"fa-solid fa-folder-plus"} onClick={() => createFolderModalRef.current.show()} />,
            <CustomButton text={"Subir archivos"} icon={"fa-solid fa-upload"} onClick={() => uploadModalRef.current.show()} />
        ])
    ])

    const getExtension = (name) => {
        let splittedName = name.split(".");
        let extension = splittedName[splittedName.length - 1];
        if (extension && splittedName.length > 1) {
            return iconsByExtensions[extension]
        } else {
            return folderIco
        }
    }

    return (
        <div>
            <Menu userData={userData} />
            <div className="col-12">
                <BootstrapModal ref={clientFormModalRef}
                    name="clientFormModal"
                    title={userFormValues.Id ? "Editar usuario" : "Crear usuario"}
                    confirmLabel="Guardar"
                    confirmAction={() => document.getElementById(submitCreateClientModal).click()}
                    confirmDisplay={true}
                    hideAction={resetClientForm}>
                    <ClientForm
                        idSubmitButton={submitCreateClientModal}
                        userFormValues={userFormValues}
                        submitClientFormCreate={submitClientFormCreate}
                        submitClientFormUpdate={submitClientFormUpdate}
                        isEditingClientForm={isEditingClientForm}
                        setIsEditingClientForm={setIsEditingClientForm}
                    />
                </BootstrapModal>
                <BootstrapModal ref={createFolderModalRef}
                    name="createFolder"
                    title="Crear carpeta"
                    confirmLabel="Crear"
                    confirmAction={() => document.getElementById(submitCreateFolderModal).click()}
                    hideAction={clearCreateFolderForm}
                    confirmDisplay={true}
                >
                    <CreateFolderForm
                        idSubmitButton={submitCreateFolderModal}
                        currentPath={currentPath}
                        submitFormCreateFolder={submitFormCreateFolder}
                        folderNameForm={folderNameForm}
                    />
                </BootstrapModal>
                <BootstrapModal ref={deleteElementModalRef}
                    name="deleteElement"
                    title="Eliminar"
                    confirmLabel="Eliminar"
                    confirmAction={deleteElement}
                    confirmDisplay={true}
                >
                    {(() => {
                        if (deleteTarget.directory) {
                            return <span>¿Está seguro de que quieres eliminar la carpeta <b style={{ textTransform: "uppercase" }}>{deleteTarget.name}</b> y todo su contenido?</span>;
                        } else {
                            return <span>¿Está seguro de que quieres eliminar el archivo <b style={{ textTransform: "uppercase" }}>{deleteTarget.name}</b>?</span>;
                        }
                    })()}
                </BootstrapModal>
                <BootstrapModal ref={uploadModalRef}
                    name="upload"
                    title="Subir archivos"
                    confirmLabel="Subir"
                    confirmDisplay={true}
                    confirmAction={() => document.getElementById(submitUploadFilesModal).click()}
                    hideAction={deleteElementModalForm}
                >
                    <UploadFilesForm submitFormUploadFiles={submitFormUploadFiles} idSubmitButton={submitUploadFilesModal} />
                </BootstrapModal>
                <div className="p-4">
                    {!paramsInit.isUsers && breadCumb.length > 0 && (
                        <nav aria-label="breadcrumb" style={{ backgroundColor: '#0D6EFD', padding: 10, marginBottom: 10, borderRadius: 5, alignItems: "center", color: "white" }}>
                            <ol className="breadcrumb" style={{ marginBottom: 0 }}>
                                <li onClick={() => handleBreadCumb('/')} style={{ cursor: 'pointer' }}><i className="fa-solid fa-home" /></li>
                                {breadCumb.map((el, index) => (
                                    <li className="breadcrumb-item" onClick={() => handleBreadCumb(el)} style={{ cursor: 'pointer' }}>
                                        {index === 0 && <a style={{ marginRight: 10, marginLeft: 10 }} href="/#">/</a>}
                                        <a href="/#">{el}</a>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    )}
                    <input type="search" name="input_search" autoComplete="off" onChange={(e) => search.set(e.target.value)} className="form-control mb-2" value={search.get} placeholder="Buscar..." />
                    <ViewType buttons={ButtonsAction} views={views.type} kanbanFunction={views.funcKanban} listFunction={views.funcList} />
                    {views.type.kanban && (
                        <div className="row">
                            {paramsInit.isFolder && getCurrentLevel() > 1 && (
                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                    <div onClick={handleGoBack}>
                                        <Folder />
                                    </div>
                                </div>
                            )}
                           {!paramsInit.isUsers && getCurrentLevel() === 0 && (
                                comun.folder.map((element, index) => (
                                    <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                        <Folder name={element.name} onClick={() => handleNavigateFolder(element)} />
                                    </div>
                                ))
                            )}
                            {getCurrentLevel() > 1 && (
                                contentsComun.filter(doFilterComun).map((element, index) => (
                                    <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                        <Folder name={element.name} onClick={(e) => element.directory ? handleNavigateFolder(element) : handleDownloadFile(e, element)}>
                                            {!element.directory && (
                                                <div className="me-2" onClick={(e) => handleDownloadFile(e, element)} style={{ cursor: "pointer", width: 30, height: 30 }}>
                                                    <i className="fa-solid fa-download"></i>
                                                </div>
                                            )}
                                            <div onClick={(e) => handleDeleteItem(e, element)} name={"deleteItemComun"} style={{ cursor: "pointer", color: 'red', width: 30, height: 30 }}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </div>
                                        </Folder>
                                    </div>
                                ))
                            )}
                            {getCurrentLevel() === 0 && (clients.length > 0 && (clients.filter(doFilter).map((client, index) => (
                                <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-6 ${showFolderComun.get || "mt-2"}`} key={index}>
                                    <div key={index} className={`card border-${!client.Active ? "secundary" : ""}`} style={{ backgroundColor: !client.Active ? "#f0f0f0" : "white", borderLeftWidth: 5, borderStyle: "solid", borderLeftColor: "#0A71FA" }}>
                                        <div className="card-body" style={{ height: 150 }}>
                                            <div className="d-flex mb-1">
                                                {paramsInit.isUsers && (<button className="btn btn-link p-1 me-1" onClick={() => handleEditFomUser(client)} data-bs-toggle="tooltip" data-bs-placement="right" title="Editar">
                                                    <i className="fa-solid fa-pencil"></i>
                                                </button>)}
                                                <h5 className="card-title" style={{ textTransform: "capitalize", marginBottom: 0, alignSelf: "center" }}>{client.Name}</h5>
                                            </div>
                                            <div className="col">
                                                <p className="mb-0">Código</p>
                                                <p className="mb-1">{client.ClientCode}</p>
                                            </div>
                                            <div className="col d-flex" style={{ justifyContent: "end", height: 40 }}>
                                                <button className="btn btn-warning" onClick={() => exploreClientContents(client.ClientCode, client.Name)} data-bs-toggle="tooltip" data-bs-placement="left" title="Explorar archivos">
                                                    <i className="fa-solid fa-folder"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))))}
                        </div>
                    )}

                    {views.type.list && (
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr>
                                    <th style={{ width: 30 }}> </th>
                                    {getCurrentLevel() === 0 && (<th style={{ width: 100 }}>Código</th>)}
                                    <th>Nombre</th>
                                    {getCurrentLevel() === 0 && ([
                                        <th style={{ width: 30 }}> </th>,
                                        <th style={{ width: 30 }}> </th>
                                    ])}
                                </tr>
                            </thead>
                            <tbody>
                                {getCurrentLevel() > 1 && (
                                    <tr style={{ cursor: "pointer" }} onClick={handleGoBack}>
                                        <td ><img src={folderBackIco} style={{ width: "2rem" }} alt="FolderBackIco" /></td>
                                        <td colSpan={'100%'}>...</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                                {!paramsInit.isUsers && getCurrentLevel() === 0 && (
                                    comun.folder.map((element, index) => (
                                        <tr style={{ cursor: "pointer" }} onClick={() => handleNavigateFolder(element)}>
                                            <td><img src={folderIco} style={{ width: "2rem" }} alt="FolderIco" /></td>
                                            <td></td>
                                            <td colSpan={'100%'}>{element.name}</td>
                                        </tr>
                                    ))
                                )}
                                {getCurrentLevel() === 0 && (clients.length > 0 && (clients.filter(doFilter).map((client, index) => (
                                    <tr style={{ cursor: !paramsInit.isUsers && "pointer" }} onClick={() => !paramsInit.isUsers && exploreClientContents(client.ClientCode, client.Name)}>
                                        <td>{paramsInit.isUsers ? <i className="fa-solid fa-user" style={{ width: "2rem" }} /> : <img src={folderIco} style={{ width: "2rem" }} alt="FolderIco" />}</td>
                                        <td>{client.ClientCode}</td>
                                        <td colSpan={'100%'}>{client.Name}</td>
                                        {paramsInit.isUsers && ([
                                            <td style={{ width: 30, cursor: "pointer" }} onClick={() => handleEditFomUser(client)}><i className="fa-solid fa-pencil"></i></td>,
                                            <td style={{ width: 30, cursor: "pointer" }} onClick={() => exploreClientContents(client.ClientCode, client.Name)}><i className="fa-solid fa-folder"></i></td>
                                        ])}
                                    </tr>
                                ))))}
                                {getCurrentLevel() > 1 && (
                                    contentsComun.filter(doFilterComun).map((element, index) => (
                                        <tr style={{ cursor: "pointer" }} onClick={(e) => element.directory ? handleNavigateFolder(element) : handleDownloadFile(e, element)}>
                                            <td><img src={getExtension(element.name)} style={{ width: "2.5rem" }} alt="extensión" /></td>
                                            <td colSpan={'100%'}>{element.name}</td>
                                            {!element.directory ? <td style={{ width: 30 }} onClick={(e) => handleDownloadFile(e, element)}><i className="fa-solid fa-download" /></td> : <td></td>}
                                            <td style={{ width: 30 }} onClick={(e) => handleDeleteItem(e, element)}><i className="fa-solid fa-trash-can" /></td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MainScreenUI