import selectPath from "../selectPath";

export default (data) => new Promise((resolve, reject) => {
    const routes = selectPath();
    let headers = new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json"
    });

    let request = new Request(routes.files.base, {
        method: "DELETE",
        headers,
        body: JSON.stringify(data),
        credentials: "include"
    });

    fetch(request)
        .then(async res => {
            if (!res.ok) {
                resolve(await res.json());
            } else {
                resolve({});
            }
        })
        .catch(err => resolve(err));
});