import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import ClientFormUI from "./ClientFormUI";

export default ({ submitClientFormCreate, submitClientFormUpdate, userFormValues,
    idSubmitButton, isEditingClientForm, setIsEditingClientForm }) => {
    const [CreateClientSchema, setCreateClientSchema] = useState();

    useEffect(() => {
        if (userFormValues.Id) {
            setIsEditingClientForm(true);
            setCreateClientSchema(Yup.object().shape({
                Name: Yup.string().required("Campo obligatorio"),
                ClientCode: Yup.number().required("Campo obligatorio"),
                Phone: Yup.number().required("Campo obligatorio"),
                Email: Yup.string().email("Debes introducir una dirección de correo válida"),
                ResponsibleEmail: Yup.string().email("Debes introducir una dirección de correo válida"),
                Password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "La contraseña debe: tener mínimo 8 caracteres, una letra minúscula, una mayúscula, un número y un símbolo").notRequired(),
                PasswordConfirmation: Yup.string().oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden').notRequired()
            }));
        } else {
            setIsEditingClientForm(false);
            setCreateClientSchema(Yup.object().shape({
                Name: Yup.string().required("Campo obligatorio"),
                ClientCode: Yup.number().required("Campo obligatorio"),
                Phone: Yup.number().required("Campo obligatorio"),
                Email: Yup.string().email("Debes introducir una dirección de correo válida"),
                ResponsibleEmail: Yup.string().email("Debes introducir una dirección de correo válida"),
                Password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "La contraseña debe: tener mínimo 8 caracteres, una letra minúscula, una mayúscula, un número y un símbolo"),
                PasswordConfirmation: Yup.string().oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden')
            }));
        }
    }, [userFormValues]);


    return <ClientFormUI
        CreateClientSchema={CreateClientSchema}
        submitClientFormCreate={submitClientFormCreate}
        submitClientFormUpdate={submitClientFormUpdate}
        userFormValues={userFormValues}
        idSubmitButton={idSubmitButton}
        isEditingClientForm={isEditingClientForm}
    />;
}