import React, { useReducer } from "react";
import FileContext from "./FileContext";
import FileReducer from "./FileReducer";
import useFile from "../../hooks/useFile";
import downloadjs from 'downloadjs';
import { toast } from "react-toastify";
import usePermissions from "../../hooks/usePermissions";

export default (props) => {

    const initialState = { currentPath: "/", contents: [] };
    const initialStateComun = { currentPath: "/", contents: [] };
    const comunFolders = ["Documentación", "Intercambios", "Común"]

    const { getContents, createFolder, deleteFile, uploadFiles, download, } = useFile();
    const { getPermissionsFolderByUser } = usePermissions()

    const [state, dispatch] = useReducer(FileReducer, initialState);
    const [stateComun, dispatchComun] = useReducer(FileReducer, initialStateComun);

    const enterFolder = async (folderName, isComun = false, user_id) => {
        let newPath = `${isComun ? stateComun.currentPath : state.currentPath}${folderName}/`
        if (isComun) {
            dispatchComun({ type: "CHANGE_PATH_COMUN", payload: newPath });
        } else {
            dispatch({ type: "CHANGE_PATH", payload: newPath });
        }
        return await retrievePathContents(newPath, isComun, user_id);
    }

    const navigateRoute = async (path, isComun = false, user_id) => {
        if (isComun) {
            dispatchComun({ type: "CHANGE_PATH_COMUN", payload: path === '/WEB' ? '/' : path });
        } else {
            dispatch({ type: "CHANGE_PATH", payload: path });
        }
        return await retrievePathContents(path, isComun, user_id);
    }

    const goOneFolderBack = async (isComun = false, user_id) => {
        let tmpCurrentPath = isComun ? stateComun.currentPath : state.currentPath;
        let splittedTmpCurrentPath = isComun ? stateComun.currentPath.split("/") : state.currentPath.split("/");
        if (splittedTmpCurrentPath[splittedTmpCurrentPath.length - 1] == "") splittedTmpCurrentPath.pop();
        splittedTmpCurrentPath.pop();
        splittedTmpCurrentPath[splittedTmpCurrentPath.length - 1] += "/";
        tmpCurrentPath = splittedTmpCurrentPath.join("/");
        if (isComun) {
            dispatchComun({ type: "CHANGE_PATH_COMUN", payload: tmpCurrentPath });
        } else {
            dispatch({ type: "CHANGE_PATH", payload: tmpCurrentPath });
        }
        return await retrievePathContents(tmpCurrentPath, isComun, user_id);
    }

    const retrievePathContents = async (path, isComun = false, user_id) => {
        let allowFolder = await getPermissionsFolderByUser(user_id)
        let result = await getContents(path);
        let resultAllow = isComun && path === '/Común/' ? result?.filter(el => allowFolder?.filter(elm => elm?.folder === el?.name).length > 0) : result
        if (Array.isArray(resultAllow)) {
            resultAllow?.sort((a, b) => (a.directory === b.directory) ? 0 : a.directory ? -1 : 1);
            if (path.split("/").filter(e => e !== "").length === 1 && path.split("/").filter(e => e !== "")[0] !== 'Común') {
                resultAllow = resultAllow.filter(el => comunFolders.indexOf(el.name) !== -1)
            }
            if (isComun) {
                dispatchComun({ type: "LOAD_CONTENTS_COMUN", payload: resultAllow });
            } else {
                dispatch({ type: "LOAD_CONTENTS", payload: resultAllow });
            }
            return resultAllow;
        } else {
            return []
        }
    }

    const ContentsComúnFolder = async () => {
        let result = await getContents("/Común");
        if (Array.isArray(result)) {
            return result?.sort((a, b) => (a.directory === b.directory) ? 0 : a.directory ? -1 : 1);
        } else {
            return []
        }
    }

    const createNewFolder = async (data, isComun = false) => {
        let result = await createFolder(data);
        if (result) {
            retrievePathContents(isComun ? stateComun.currentPath : state.currentPath, isComun);
        }
        return result;
    }

    const deleteElement = async (data, isComun = false) => {
        let result = await deleteFile(data);
        if (result.type != "error") {
            retrievePathContents(isComun ? stateComun.currentPath : state.currentPath, isComun);
        }
        return result;
    }

    const uploadElements = async (formData, isComun = false) => {
        let path = isComun ? stateComun.currentPath.replaceAll("/", "-") : state.currentPath.replaceAll("/", "-")
        let result = await uploadFiles("/" + path, formData);
        if (result.type == "success") retrievePathContents(isComun ? stateComun.currentPath : state.currentPath, isComun);
        return result;
    }

    const downloadFile = async (filename, isComun = false) => {
        let result = await download(isComun ? stateComun.currentPath : state.currentPath, filename);
        downloadjs(result, filename);
        if (result instanceof Blob) {
            return true;
        } else {
            return false;
        }

    }

    const getBreadcumb = (isComun = false) => isComun ? stateComun.currentPath.split("/").filter(e => e !== "") : state.currentPath.split("/").filter(e => e !== "");

    const getCurrentLevel = (isComun = false) => {
        let splittedCurrentPath = isComun ? stateComun.currentPath.split("/") : state.currentPath.split("/");
        splittedCurrentPath.shift();
        splittedCurrentPath.pop();
        return splittedCurrentPath.length;
    }

    return (
        <FileContext.Provider
            value={{
                state,
                stateComun,
                enterFolder,
                getBreadcumb,
                goOneFolderBack,
                createNewFolder,
                deleteElement,
                uploadElements,
                downloadFile,
                getCurrentLevel,
                navigateRoute,
                ContentsComúnFolder
            }}
        >
            {props.children}
        </FileContext.Provider>
    )
}