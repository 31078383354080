import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import UserContext from "../../contexts/UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import PermissionScreenUI from "./PermissionScreenUI"
import FileContext from "../../contexts/FileContext/FileContext";
import CheckResultNavFolder from "../../components/GeneralFunctions/CheckResultNavFolder";

const PermissionScreen = () => {

    //#region 'VARIABLES'
    let navigate = useNavigate();
    //#endregion

    //#region 'USE REF'
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext);
    const fileContext = useContext(FileContext);
    //#endregion


    //#region 'USE STATES'
    const [userData, setUserData] = useState({});
    const [contentComun, setContentComun] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getDataComun = async () => {
        let result = await fileContext?.ContentsComúnFolder()
        setContentComun(result)
        CheckResultNavFolder(result)
    }

    const checkSession = async () => {
        let data = userContext.state?.sessionData?.Id ? userContext.state.sessionData : await userContext.getSessionData()
        setUserData(data);
        data.IdRole === 2 && navigate("/explorer")
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => { checkSession() }, [])

    useEffect(() => { getDataComun() }, [])
    //#endregion

    return <PermissionScreenUI
        userData={userData}
        contentsComun={contentComun}
        userList={userContext.state.clients?.filter(el => el.IdRole != 1)}
    />
}

export default PermissionScreen