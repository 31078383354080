import React from "react";
import { Formik, Form, Field } from "formik";

export default ({ submitForm, idSubmitButton, CreateFolderSchema, folderNameForm }) => {
    return (
        <Formik
            initialValues={folderNameForm}
            validationSchema={CreateFolderSchema}
            onSubmit={submitForm}>
            {({ errors, touched }) => (
                <Form>
                    <div className="form-group mb-2">
                        <label htmlFor="name">Nombre: </label>
                        <Field type="text" name="Name" id="name" className="form-control" required />
                        {errors.Name && touched.Name ? (
                            <div className="error">{errors.Name}</div>
                        ) : null}
                    </div>
                    <div>
                        <Field type="submit" style={{ display: "none" }} value="Enviar" id={idSubmitButton} />
                    </div>
                </Form>
            )}
        </Formik >
    );
}