//#region IMPORTS
import React from "react";
import Menu from "../../components/Menu/Menu";
import { useNavigate } from "react-router-dom";
import CreateFolderForm from "../../components/CreateFolderForm/CreateFolderForm";
import UploadFilesForm from "../../components/UploadFilesForm/UploadFilesForm";
import BootstrapModal from "../../components/BootstrapModal";
import CustomButton from "../../components/CustomButton/CustomButton";
import { ViewType } from "../../components/ViewType/ViewType";
import {
    folderIco, docIco, docxIco, jpegIco, jpgIco,
    odpIco, odsIco, odtIco, pdfIco, pngIco, pptIco, pptxIco, txtIco, xlsIco, xlsxIco,
    rarIco, zipIco
} from "../../Constants/ImgPath";
import BackButtonKanban from "../../components/KanbanView/BackButtonKanban";
import BackButtonList from "../../components/ListView/BackButtonList";
import ItemKanban from "../../components/KanbanView/ItemKanban";
//#endregion

const ExplorerScreenUI = ({ clickElement, contents, goBack,
    currentPath, createFolderModalRef, deleteElementModalRef,
    submitFormCreateFolder, deleteTarget, setDeleteTarget,
    deleteElement, uploadModalRef, submitFormUploadFiles, currentPathComun,
    userData, folderNameForm, comun, doFilterComun, search, doFilter,
    currentLevel, currentLevelComun, handleBreadCumb,
    views = { type: {}, funcKanban: undefined, funcList: undefined },
    paramsInit = { isUsers: false, isFolder: false },
    handleDownloadFile, contentsComun, setIsComun, breadCumb }) => {

    //#region VARIABLES
    let navigate = useNavigate();
    const onlyForAdminFolders = ["Documentación", "Común"];
    const submitCreateFolderModal = "submitCreateFolderModal";
    const submitUploadFilesModal = "submitUploadFilesModal";
    const iconsByExtensions = {
        doc: docIco,
        docx: docxIco,
        odt: odtIco,
        jpeg: jpegIco,
        jpg: jpgIco,
        png: pngIco,
        txt: txtIco,
        pdf: pdfIco,
        ppt: pptIco,
        pptx: pptxIco,
        odp: odpIco,
        xls: xlsIco,
        xlsx: xlsxIco,
        ods: odsIco,
        zip: zipIco,
        rar: rarIco,
    }
    //#endregion

    //#region FUNCTIONS
    const clearCreateFolderForm = () => {
        document.getElementById("name").value = "";
    }

    const deleteElementModalForm = () => document.getElementById("files").value = ""

    const handleDeleteItem = (e, element, isComun = false) => {
        e.stopPropagation();
        setDeleteTarget({ ...deleteTarget, data: element, isComun: isComun });
        deleteElementModalRef.current.show()
    }

    const handleNavigateFolder = (element, isComun = false) => element.directory && clickElement(element, isComun, userData.Id)

    const getExtension = (name) => {
        let splittedName = name.split(".");
        let extension = splittedName[splittedName.length - 1];

        if (extension && splittedName.length > 1) {
            return iconsByExtensions[extension]
        } else {
            return folderIco
        }
    }
    //#endregion

    //#region  COMPONENTES
    const ButtonsAction = (
        <div className="col">
            {userData.IdRole === 1 && <CustomButton text={"Inicio"} icon={"fa-solid fa-home"} onClick={() => navigate("/main", { state: { isUsers: paramsInit.isUsers, isFolder: paramsInit.isFolder } })} />}
            {(onlyForAdminFolders.indexOf(currentPath.split("/").filter(e => e !== "")[1]) == -1 || userData.IdRole === 1) && currentLevel > 1 && ([
                <CustomButton text={"Crear carpeta"} icon={"fa-solid fa-folder-plus"} onClick={() => createFolderModalRef.current.show()} />,
                <CustomButton text={"Subir archivos"} icon={"fa-solid fa-upload"} onClick={() => uploadModalRef.current.show()} />
            ])}
            {!(onlyForAdminFolders.indexOf(currentPathComun.split("/").filter(e => e !== "")[1]) == 0 || userData.IdRole !== 1) && currentLevelComun > 0 && ([
                <CustomButton text={"Crear carpeta"} icon={"fa-solid fa-folder-plus"} onClick={() => {
                    createFolderModalRef.current.show()
                    setIsComun(true)
                }} />,
                <CustomButton text={"Subir archivos"} icon={"fa-solid fa-upload"} onClick={() => {
                    uploadModalRef.current.show()
                    setIsComun(true)
                }} />
            ])
            }
        </div>
    )
    //#endregion

    return (
        <div>
            <Menu userData={userData} />
            <BootstrapModal ref={createFolderModalRef}
                name="createFolder"
                title="Crear carpeta"
                confirmLabel="Crear"
                confirmAction={() => document.getElementById(submitCreateFolderModal).click()}
                hideAction={clearCreateFolderForm}
                confirmDisplay={true}
            >
                <CreateFolderForm
                    idSubmitButton={submitCreateFolderModal}
                    currentPath={currentPath}
                    submitFormCreateFolder={submitFormCreateFolder}
                    folderNameForm={folderNameForm}
                />
            </BootstrapModal>

            <BootstrapModal ref={deleteElementModalRef}
                name="deleteElement"
                title="Eliminar"
                confirmLabel="Eliminar"
                confirmAction={deleteElement}
                confirmDisplay={true}
            >
                {(() => {
                    if (deleteTarget.data.directory) {
                        return <span>¿Está seguro de que quiere eliminar la carpeta <b style={{ textTransform: "uppercase" }}>{deleteTarget.data.name}</b> y todo su contenido?</span>;
                    } else {
                        return <span>¿Está seguro de que quiere eliminar el archivo <b style={{ textTransform: "uppercase" }}>{deleteTarget.data.name}</b>?</span>;
                    }
                })()}
            </BootstrapModal>

            <BootstrapModal ref={uploadModalRef}
                name="upload"
                title="Subir archivos"
                confirmLabel="Subir"
                confirmDisplay={true}
                confirmAction={() => document.getElementById(submitUploadFilesModal).click()}
                hideAction={deleteElementModalForm}
            >
                <UploadFilesForm submitFormUploadFiles={submitFormUploadFiles} idSubmitButton={submitUploadFilesModal} />
            </BootstrapModal>
            <div className="p-4">
                {breadCumb(currentLevelComun > 0).length > (currentLevelComun > 0 ? 0 : 1) && (
                    <nav aria-label="breadcrumb" style={{ backgroundColor: '#0D6EFD', padding: 10, marginBottom: 10, borderRadius: 5, alignItems: "center", color: "white" }}>
                        <ol className="breadcrumb" style={{ marginBottom: 0 }}>
                            <li onClick={() => handleBreadCumb('/', currentLevelComun > 0)} style={{ cursor: 'pointer' }}><i className="fa-solid fa-home" /></li>
                            {breadCumb(currentLevelComun > 0)?.map((el, index) => (
                                <li className="breadcrumb-item" onClick={() => handleBreadCumb(el, currentLevelComun > 0)} style={{ cursor: 'pointer' }}>
                                    {(currentLevelComun > 0 && index === 0) && <a style={{ marginRight: 10, marginLeft: 10 }}>/</a>}
                                    <a>{currentLevelComun === 0 ? index === 0 ? '' : el : el}</a>
                                </li>
                            ))}
                        </ol>
                    </nav>
                )}
                <input type="search" name="input_search" autoComplete="off" onChange={(e) => search.set(e.target.value)} className="form-control" value={search.get} placeholder="Buscar..." />
                <div className="row mb-2 mt-3">
                    <ViewType
                        buttons={ButtonsAction}
                        views={views.type}
                        kanbanFunction={views.funcKanban} listFunction={views.funcList}
                    />
                </div>
                {views.type.kanban && (
                    <div className="row">
                        <BackButtonKanban visible={currentLevel > 1} onClickBack={() => goBack()} />
                        <BackButtonKanban visible={currentLevelComun > 0} onClickBack={() => goBack(true)} />
                        <ItemKanban data={comun.folder} isComun
                            visible={!(currentLevelComun > 0 || currentLevel > 1)}
                            onClickElement={handleNavigateFolder}
                        />
                        <ItemKanban data={contentsComun.filter(doFilterComun)} isComun
                            visible={currentLevelComun > 0}
                            onClickElement={handleNavigateFolder}
                            otherOnclick={handleDownloadFile}
                            visibleDownload={currentLevelComun > 0}
                            onClickDownload={handleDownloadFile}
                            visibleDelete={!(onlyForAdminFolders.indexOf(currentPathComun.split("/").filter(e => e !== "")[1]) == 0 || userData.IdRole !== 1) && currentLevelComun > 0}
                            onClickDelete={handleDeleteItem}
                        />
                        <ItemKanban data={contents.filter(doFilter)}
                            visible={currentLevelComun === 0}
                            onClickElement={handleNavigateFolder}
                            otherOnclick={handleDownloadFile}
                            visibleDownload={currentLevel > 1}
                            onClickDownload={handleDownloadFile}
                            visibleDelete={(onlyForAdminFolders.indexOf(currentPath.split("/").filter(e => e !== "")[1]) == -1 || userData.IdRole === 1) && currentLevel > 1}
                            onClickDelete={handleDeleteItem}
                        />
                    </div>
                )}
                {views.type.list && (
                    <table className="table table-striped mt-2">
                        <thead>
                            <tr>
                                <th style={{ width: 30 }}> </th>
                                <th>Nombre</th>
                                <th style={{ width: 30 }}> </th>
                                <th style={{ width: 30 }}> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <BackButtonList visible={currentLevel > 1} onClickBack={() => goBack()} />
                            <BackButtonList visible={currentLevelComun > 0} onClickBack={() => goBack(true)} />
                            {!(currentLevelComun > 0 || currentLevel > 1) && (
                                comun.folder.map((element) => (
                                    <tr style={{ cursor: "pointer" }} onClick={() => handleNavigateFolder(element, true)} >
                                        <td><img src={folderIco} style={{ width: "2rem" }} /></td>
                                        <td colSpan={'100%'}>{element.name}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                ))
                            )}
                            {currentLevelComun > 0 && (
                                contentsComun.filter(doFilterComun).map((element, index) => (
                                    <tr style={{ cursor: "pointer" }} onClick={(e) => element.directory ? handleNavigateFolder(element, true) : handleDownloadFile(e, element, true)}>
                                        <td><img src={getExtension(element.name)} style={{ width: "2rem" }} /></td>
                                        <td colSpan={'100%'}>{element.name}</td>
                                        {currentLevelComun > 0 && !element.directory ? (
                                            <td style={{ width: 30 }} onClick={(e) => handleDownloadFile(e, element, true)}>
                                                <i className="fa-solid fa-download" />
                                            </td>
                                        ) : <td> </td>}
                                        {!(onlyForAdminFolders.indexOf(currentPathComun.split("/").filter(e => e !== "")[1]) == 0 || userData.IdRole !== 1) && currentLevelComun > 0 ? (
                                            <td style={{ width: 30 }} onClick={(e) => handleDeleteItem(e, element, true)}>
                                                <i className="fa-solid fa-trash-can" />
                                            </td>
                                        ) : <td> </td>}
                                    </tr>
                                )))
                            }
                            {currentLevelComun === 0 && (
                                contents.filter(doFilter).map((element) => (
                                    <tr style={{ cursor: "pointer" }} onClick={(e) => element.directory ? handleNavigateFolder(element) : handleDownloadFile(e, element)}>
                                        <td><img src={getExtension(element.name)} style={{ width: "2.5rem" }} /></td>
                                        <td colSpan={'100%'}>{element.name}</td>
                                        {currentLevel > 1 && !element.directory ?
                                            <td style={{ width: 30 }} onClick={(e) => handleDownloadFile(e, element)}>
                                                <i className="fa-solid fa-download" />
                                            </td> : <td> </td>
                                        }
                                        {(onlyForAdminFolders.indexOf(currentPath.split("/").filter(e => e !== "")[1]) == -1 || userData.IdRole === 1) && currentLevel > 1 ? (
                                            <td style={{ width: 30, }} onClick={(e) => handleDeleteItem(e, element)}>
                                                <i className="fa-solid fa-trash-can" />
                                            </td>) : <td> </td>}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default ExplorerScreenUI