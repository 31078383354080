const domain = "mstechcorp.dyndns.org";
const localDomain = "mstechcorp.dyndns.org"
const apiPrefix = "/api";
const port = 5000;

const uriDefault = `https://${domain}:${port}${apiPrefix}`;
const uriLocal = `https://${localDomain}:${port}${apiPrefix}`;

const usersBaseDefault = uriDefault + "/users";
const filesBaseDefault = uriDefault + "/files";
const permissionsBaseDefault = uriDefault + "/permissions";

const usersBaseLocal = uriLocal + "/users";
const filesBaseLocal = uriLocal + "/files";
const permissionsBaseLocal = uriLocal + "/permissions";


export default {
    default: {
        users: {
            base: usersBaseDefault,
            login: usersBaseDefault + "/login",
            getData: usersBaseDefault + "/get-data",
            logout: usersBaseDefault + "/logout",
        },
        files: {
            base: filesBaseDefault,
            comun: filesBaseDefault + "/comun",
            createFolder: filesBaseDefault + "/create-folder",
            uploadFiles: filesBaseDefault + "/upload",
            download: filesBaseDefault + "/download"
        },
        permissions: {
            base: permissionsBaseDefault,
            getPermissionUser: permissionsBaseDefault + "/get_permission_user",
        }
    },
    local: {
        users: {
            base: usersBaseLocal,
            login: usersBaseLocal + "/login",
            getData: usersBaseLocal + "/get-data",
            logout: usersBaseLocal + "/logout",
        },
        files: {
            base: filesBaseLocal,
            comun: filesBaseLocal + "/comun",
            createFolder: filesBaseLocal + "/create-folder",
            uploadFiles: filesBaseLocal + "/upload",
            download: filesBaseLocal + "/download"
        },
        permissions: {
            base: permissionsBaseLocal,
            getPermissionUser: permissionsBaseLocal + "/get_permission_user",
        }
    }
}