import React from "react";
import logo from "../../img/logo.png"
import { Formik, Form, Field } from "formik";
import packageJson from '../../../package.json'

export default ({ submitForm, LoginSchema }) => {
    return (
        <div style={{ height: "80vh", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <img src={logo} style={{ margin: "30px auto" }} />
            <div className="container" style={{ display: "flex", justifyContent: "center" }}>
                <div id="loginCard" className="card" style={{ alignSelf: "center", border: 0 }}>
                    <div className="card-body">
                        <h3 className="card-title" style={{ marginBottom: 30 }}>Login</h3>
                        <Formik initialValues={{ Email: "", Password: "" }}
                            onSubmit={submitForm} validationSchema={LoginSchema}>
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="form-group mb-2">
                                        <label htmlFor="email">E-mail: </label>
                                        <Field type="text" name="Email" id="email" className="form-control" required />
                                        {errors.Email && touched.Email ? (
                                            <div>{errors.Email}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="password">Contraseña: </label>
                                        <Field type="password" name="Password" id="password" className="form-control" required />
                                        {errors.Password && touched.Password ? (
                                            <div>{errors.Password}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="form-control btn btn-primary">Iniciar sesión</button>
                                    </div>
                                    <fieldset style={{ textAlign: 'center' }}>
                                        <em style={{ fontSize: 11 }}>{`Versión ${packageJson.version} by Movalnet Teschnology, SL`}</em>
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}