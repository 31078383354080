import PostGetContents from "../services/Files/PostGetContents";
import PostCreateFolder from "../services/Files/PostCreateFolder";
import Delete from "../services/Files/Delete";
import Post from "../services/Files/Post";
import PostDownload from "../services/Files/PostDownload";
import PostGetContentsComun from "../services/Files/PostGetContentsComun";

export default () => {

    const uploadFiles = (currentPath, formData) => Post(currentPath, formData);

    const download = (currentPath, filename) => PostDownload(currentPath, filename);

    const deleteFile = (data) => Delete(data);

    const createFolder = (data) => PostCreateFolder(data);

    const getContents = (path) => PostGetContents(path);

    const getContentsComun = () => PostGetContentsComun();

    return { uploadFiles, deleteFile, createFolder, getContents, download, getContentsComun }
}