import React, { useReducer, useEffect } from "react";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import useUser from "../../hooks/useUser";

export default (props) => {
    const { getData, getLogout, getClients } = useUser();

    const initialState = { clients: [], sessionData: {} };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    useEffect(() => {
        (async () => {
            if (state?.sessionData?.Id) {
                if (state.sessionData.IdRole == 1) {
                    retrieveClients();
                }
            }
        })();
    }, [state?.sessionData?.Id]);

    const getSessionData = async () => {
        if (state?.sessionData?.Id) {
            dispatch({ type: "GET_USER", payload: state.sessionData })
            return state.sessionData
        } else {
            let data = await getData();
            dispatch({ type: "GET_USER", payload: data });
            return data;
        }
    }

    const setSessionData = async (data) => {
        dispatch({ type: "GET_USER", payload: data });
        return data;
    }

    const logout = async () => {
        let result = await getLogout();
        dispatch({ type: "GET_USER", payload: {} });
        return result;
    }

    const retrieveClients = async () => {
        let clients = await getClients();
        if (Array.isArray(clients)) {
            clients?.sort((a, b) => (a.Active === b.Active) ? 0 : a.Active ? -1 : 1)
            dispatch({ type: "GET_CLIENTS", payload: clients });
            return clients;
        } else {
            return []
        }
    }

    return (
        <UserContext.Provider
            value={{
                state,
                setSessionData,
                getSessionData,
                logout,
                retrieveClients
            }}>
            {props.children}
        </UserContext.Provider>
    );
}