import IconTextResponsive from "../IconTextResponsive/IconTextResponsive"

const CustomButton = ({ text, onClick, classBtn, icon, style}) => (
    <button
        className={`btn btn-${classBtn || 'primary'} mt-1 me-1`}
        style={style}
        onClick={onClick}>
        {icon ? <IconTextResponsive icoClasses={icon} msg={text} /> : text}
    </button>
)

export default CustomButton