import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext/UserContext";
import MenuUI from "./MenuUI";

export default ({ userData }) => {
    const userContext = useContext(UserContext);
    let navigate = useNavigate();

    const doLogout = async () => {
        await userContext.logout();
        navigate("/");
    }

    const toFolder = () => navigate("/main", { state: { isFolder: true } })
    const toUsers = () => navigate("/main", { state: { isUsers: true } })
    const toPermissions = () => navigate("/permissions")
    
    return <MenuUI
        userData={userData}
        doLogout={doLogout}
        routes={{
            toFolder: toFolder,
            toUsers: toUsers,
            toPermissions:toPermissions
        }}
    />
}

/* window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";//esta linea es necesaria para chrome
        window.onhashchange = function () { window.location.hash = "no-back-button"; } */