import selectPath from "../selectPath";

const GetByFolder = (folder) => new Promise((resolve, reject) => {
    const routes = selectPath();
    let headers = new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json"
    });
    let route = routes.permissions.base + "/" + folder
    let request = new Request(route, {
        method: "GET",
        headers,
        credentials: "include",
    });

    fetch(request)
        .then(res => res.json())
        .then(data => resolve(data))
        .catch(err => resolve(err));
});

export default GetByFolder