//#region IMPORTS
import React, { useRef, useContext, useState, useLayoutEffect, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../contexts/UserContext/UserContext";
import MainScreenUI from "./MainScreenUI";
import useUser from "../../hooks/useUser";
import MessageService from "../../services/MessageService";
import FileContext from "../../contexts/FileContext/FileContext";
import { useShowView } from "../../hooks/useShowView";
import CheckResultNavFolder from "../../components/GeneralFunctions/CheckResultNavFolder";
//#endregion

const MainScreen = () => {
    //#region VARIABLES
    let navigate = useNavigate();
    let messageService = new MessageService();
    const objUserForm = {
        Name: "",
        ClientCode: "",
        Phone: "",
        Email: "",
        ResponsibleEmail: "",
        Password: "",
        PasswordConfirmation: "",
        Active: true
    }
    const isUsers = useLocation()?.state?.isUsers || undefined
    const isFolder = useLocation()?.state?.isFolder || undefined
    //#endregion

    //#region USE REF
    const createFolderModalRef = useRef();
    const deleteElementModalRef = useRef();
    const uploadModalRef = useRef();
    const clientFormModalRef = useRef();
    //#endregion

    //#region USE CONTEXT
    const userContext = useContext(UserContext);
    const fileContext = useContext(FileContext);
    //#endregion

    //#region CUSTOM HOOKS
    const { createClient, updateClient } = useUser();
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: false })
    //#endregion

    //#region USE STATE
    const [isEditingClientForm, setIsEditingClientForm] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState({});
    const [folderNameForm, setFolderNameForm] = useState({ Name: "" });
    const [userFormValues, setUserFormValues] = useState(objUserForm);
    const [comunFolder, setComunFolder] = useState([])
    const [contentsComun, setContentsComun] = useState([])
    const [showComun, setShowComun] = useState(false)
    const [showFolderComun, setShowFolderComun] = useState(false)
    const [search, setSearch] = useState("")
    const [userData, setUserData] = useState({});
    //#endregion

    //#region USE LAYOUT AND USE EFFECT
    useLayoutEffect(() => {
        (async () => {
            let data = userContext.state?.sessionData?.Id ? userContext.state.sessionData : await userContext.getSessionData()
            console.log("🚀 ~ file: MainScreen.js:65 ~ data:", data)
            setUserData(data);
            data.IdRole === 2 && navigate("/explorer")
        })()
    }, [])

    useEffect(() => {
        getDataComun()
        isUsers && resetValues()
        clearBodyAndBackDrop()
        window.addEventListener("popstate", resetValues)
        return () => window.removeEventListener("popstate", resetValues)
    }, [isUsers])
    //#endregion

    //#region FUNCTIONS
    const clearBodyAndBackDrop = () => {
        const elementBody = document.getElementsByTagName('body')[0]
        elementBody.className = ""
        elementBody.style = ""
        const elementModal = document.getElementsByClassName('modal-backdrop fade show')[0]
        elementModal?.remove()
    }

    const resetValues = () => {
        clientFormModalRef.current.hide()
        createFolderModalRef.current.hide()
        deleteElementModalRef.current.hide()
        uploadModalRef.current.hide()
        handleEventBack('/')
    }

    const handleEventBack = async (element) => {
        let arrPath = fileContext.stateComun.currentPath.split("/").filter(e => e !== "")
        let indexPath = arrPath.findIndex(el => el === element)
        let newArrPath = arrPath.filter((el, index) => index <= indexPath)
        let result = await fileContext?.navigateRoute('/' + newArrPath.join('/') + '/', true, userData.Id)
        CheckResultNavFolder(result)
    }

    const getDataComun = async () => {
        let result = await fileContext?.enterFolder("", false, userData.Id)
        setComunFolder(result?.filter(el => el.name === "Común"))
        CheckResultNavFolder(result)
    }

    const getClientFormInitialValues = () => setUserFormValues(objUserForm);

    const exploreClientContents = (clientCode, clientName) => navigate(`/explorer/${clientCode}_${clientName}`, { state: { isUsers, isFolder } });

    const submitClientFormCreate = async (values, { resetForm }) => {
        let result = await createClient(values);
        if (result.Id) {
            messageService.success("Cliente creado");
            resetForm(getClientFormInitialValues());
            clientFormModalRef.current.hide();
            userContext.retrieveClients();
        } else {
            for (let error of result.errors) {
                switch (error.param) {
                    case "ClientCode":
                        messageService.error("Código de cliente ya registrado");
                        break;
                    case "Email":
                        messageService.error("Email ya registrado");
                        break;
                    case "Phone":
                        messageService.error("Teléfono fuera de rango");
                        break;
                    default:
                        messageService.error("Error interno");
                        break;
                }
            }
        }
    }

    const submitClientFormUpdate = async (values, { resetForm }) => {
        let result = await updateClient(values);
        if (result.Id) {
            setIsEditingClientForm(false);
            messageService.success("Cliente actualizado");
            resetForm(getClientFormInitialValues());
            clientFormModalRef.current.hide();
            userContext.retrieveClients();
        } else if (result.code === 8) {
            messageService.error("No hay cambios");
        } else {
            messageService.error("Error interno");
        }
    }

    const clickElement = async (data) => {
        let result = await fileContext.enterFolder(data.name, true, userData.Id);
        CheckResultNavFolder(result)
    }

    const goBack = () => fileContext.goOneFolderBack(true, userData.Id);

    const doFilter = (item) => {
        let client = item.Name + ' ' + item.ClientCode
        if (!search.trim()) {
            return item
        } else if (client && client.toLowerCase().includes(search.toLocaleLowerCase())) {
            return item
        }
    }

    const doFilterComun = (item) => {
        if (!search.trim()) {
            return item
        } else if (item.name && item.name.toLowerCase().includes(search.toLocaleLowerCase())) {
            return item
        }
    }

    const getCreateFolderFormInitialValues = () => setFolderNameForm({ Name: "" });

    const submitFormCreateFolder = async (values, { resetForm }) => {
        values.CurrentPath = fileContext.stateComun.currentPath;
        let result = await fileContext.createNewFolder(values, true);
        if (result?.type !== "error") {
            resetForm(getCreateFolderFormInitialValues())
            createFolderModalRef.current.hide();
            return;
        }

        if (result.code === 4) {
            messageService.error("Ya existe");
        } else if (result.code === 3) {
            messageService.error("Sin permisos");
        } else {
            messageService.error("Error interno");
        }
    }

    const submitFormUploadFiles = async (evt) => {
        evt.preventDefault();
        const files = evt.target.files.files;
        let formData = new FormData();
        for (let index = 0; index < files.length; index++) {
            formData.append('userUpload', files[index]);
        }
        let result = await fileContext.uploadElements(formData, true);
        if (result.type === "success") {
            uploadModalRef.current.hide();
        } else if (result.code === 10) {
            messageService.error("Error interno");
        } else if (result.code === 11 || result.code === 12) {
            messageService.error("Los archivos no cumplen con los requisitos");
        } else if (result.code === 3) {
            messageService.error("Sin permisos");
        } else if (result.code === 13) {
            messageService.error("Quota excedida");
        } else {
            messageService.error("Error interno");
        }
    }

    const deleteElement = async () => {
        let result = await fileContext.deleteElement({ CurrentPath: `${fileContext.stateComun.currentPath}${deleteTarget.name}`, Directory: deleteTarget.directory }, true)
        if (result.type !== "error") {
            setDeleteTarget({});
            deleteElementModalRef.current.hide();
            return;
        }

        if (result.code === 5) {
            messageService.error("El archivo o carpeta existe");
        } else if (result.code === 3) {
            messageService.error("Sin Permisos");
        } else {
            messageService.error("Error interno");
        }
    }

    const handleDownloadFile = (e, data) => {
        e.stopPropagation()
        fileContext.downloadFile(data.name, true)
    }
    //#endregion

    return <MainScreenUI
        clientFormModalRef={clientFormModalRef}
        userFormValues={userFormValues}
        setUserFormValues={setUserFormValues}
        submitClientFormCreate={submitClientFormCreate}
        submitClientFormUpdate={submitClientFormUpdate}
        clients={userContext.state.clients}
        exploreClientContents={exploreClientContents}
        getClientFormInitialValues={getClientFormInitialValues}
        isEditingClientForm={isEditingClientForm}
        setIsEditingClientForm={setIsEditingClientForm}
        contentsComun={fileContext.stateComun.contents}
        clickElement={clickElement}
        currentPath={fileContext.state.currentPath}
        currentPathComun={fileContext.stateComun.currentPath}
        comun={{
            folder: comunFolder,
            setFolder: setComunFolder,
            contentFolder: contentsComun,
            setContentFolder: setContentsComun,
            visible: showComun,
            setVisible: setShowComun
        }}
        showFolderComun={{
            get: showFolderComun,
            set: setShowFolderComun
        }}
        goBack={goBack}
        search={{
            get: search,
            set: setSearch
        }}
        doFilter={doFilter}
        doFilterComun={doFilterComun}
        paramsInit={{
            isFolder, isUsers
        }}
        uploadModalRef={uploadModalRef}
        submitFormCreateFolder={submitFormCreateFolder}
        getCreateFolderFormInitialValues={getCreateFolderFormInitialValues}
        deleteTarget={deleteTarget}
        setDeleteTarget={setDeleteTarget}
        deleteElement={deleteElement}
        createFolderModalRef={createFolderModalRef}
        deleteElementModalRef={deleteElementModalRef}
        submitFormUploadFiles={submitFormUploadFiles}
        folderNameForm={folderNameForm}
        handleDownloadFile={handleDownloadFile}
        userData={userData}
        breadCumb={fileContext.getBreadcumb(true)}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        handleBreadCumb={handleEventBack}
    />
}
export default MainScreen