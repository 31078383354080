import { folderBackIco } from "../../Constants/ImgPath";

export default ({ visible, onClickBack }) => (
    visible && (
        <tr style={{ cursor: "pointer" }} onClick={onClickBack}>
            <td ><img src={folderBackIco} style={{ width: "2rem" }} /></td>
            <td colSpan={'100%'}>...</td>
            <td></td>
            <td></td>
        </tr>
    )
)