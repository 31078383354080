import React from "react"
import ItemMenu from "./ItemMenu";
import { logoCompany } from "../../Constants/ImgPath";

export default ({ userData, doLogout, routes = { toFolder: '', toUsers: '', toPermissions: '' } }) => (
    <nav className="navbar navbar-expand-lg bg-light mb-2">
        <div className="container-fluid">
            <a className="navbar-brand" onClick={routes.toUsers} style={{ cursor: "pointer", flex: 1 }}>
                <img id="logo" src={logoCompany} />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                    {userData.IdRole === 1 && ([
                        <ItemMenu onClickNav={routes.toFolder} text={"Carpetas"} />,
                        <ItemMenu onClickNav={routes.toPermissions} text={"Permisos"} />,
                        <ItemMenu onClickNav={routes.toUsers} text={"Usuarios"} />
                    ])}
                    <ItemMenu onClickNav={doLogout} text={"Cerrar sesión"} content={[
                        <em style={{ fontSize: "0.8rem", display: "block" }}>{userData.Name}</em>,
                        <em style={{ fontSize: "0.8rem", display: "block" }}>{userData.ClientCode}</em>
                    ]} />
                </ul>
            </div>
        </div>
    </nav>
)