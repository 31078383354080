import React from "react";

export default ({ idSubmitButton, submitForm }) => {

    let acceptedMimetypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.text",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "text/plain",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/octet-stream",
        "application/x-zip-compressed"
    ];

    return (
        <form onSubmit={submitForm} >
            <div className="form-group">
                <label htmlFor="files">Archivos</label>
                <br />
                <input type="file" name="files" id="files" multiple accept={acceptedMimetypes.join(", ")} />
            </div>
            <div>
                <input type="submit" style={{ display: "none" }} value="Enviar" id={idSubmitButton} />
            </div>
        </form>
    )
}