import React from "react";
import { Formik, Form, Field } from "formik";

export default ({ CreateClientSchema, submitClientFormCreate,
    submitClientFormUpdate, userFormValues, idSubmitButton, isEditingClientForm }) => {
    return (
        <Formik
            initialValues={userFormValues} enableReinitialize
            onSubmit={!isEditingClientForm ? submitClientFormCreate : submitClientFormUpdate}
            validationSchema={CreateClientSchema} >
            {({ errors, touched, resetForm }) => (
                <Form>
                    <div className="form-group mb-2">
                        <label htmlFor="name">Nombre: </label>
                        <Field type="text" name="Name" id="name" className="form-control" required />
                        {errors.Name && touched.Name && (
                            <div className="error">{errors.Name}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="client-code">Código usuario: </label>
                        <Field type="number" name="ClientCode" id="client-code" className="form-control" required disabled={isEditingClientForm} autoComplete="off" />
                        {errors.ClientCode && touched.ClientCode && (
                            <div className="error">{errors.ClientCode}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="phone">Teléfono: </label>
                        <Field type="number" name="Phone" id="phone" className="form-control" required autoComplete="off" />
                        {errors.Phone && touched.Phone && (
                            <div className="error">{errors.Phone}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="email">Email: </label>
                        <Field type="email" name="Email" id="email" className="form-control" required autoComplete="off" />
                        {errors.Email && touched.Email && (
                            <div className="error">{errors.Email}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="responsible-email">Email responsable: </label>
                        <Field type="email" name="ResponsibleEmail" id="responsible-email" className="form-control" required autoComplete="off" />
                        {errors.ResponsibleEmail && touched.ResponsibleEmail && (
                            <div className="error">{errors.ResponsibleEmail}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="password">Contraseña: </label>
                        <Field type="password" name="Password" id="password" className="form-control" required={!isEditingClientForm} autoComplete="new-password" />
                        {errors.Password && touched.Password && (
                            <div className="error">{errors.Password}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="password-confirmation">Confirmar contraseña: </label>
                        <Field type="password" name="PasswordConfirmation" id="password-confirmation" className="form-control" required={!isEditingClientForm} autoComplete="off" />
                        {errors.PasswordConfirmation && touched.PasswordConfirmation && (
                            <div className="error">{errors.PasswordConfirmation}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="active">Activo: </label>
                        <Field type="checkbox" name="Active" id="active" className="form-check-input" style={{ marginTop: "0.4rem", marginLeft: "0.5rem" }} autoComplete="off" />
                        {errors.Active && touched.Active && (
                            <div className="error">{errors.Active}</div>
                        )}
                    </div>
                    <div className="form-group mb-2">
                        <Field type="submit" id={idSubmitButton} style={{ display: "none" }} value="Enviar" />
                    </div>
                </Form>
            )}
        </Formik>
    );
}