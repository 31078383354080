export default (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "CHANGE_PATH":
            return {
                ...state, currentPath: payload
            };
            break;
        case "LOAD_CONTENTS":
            return {
                ...state, contents: payload
            }
            break;
        case "CHANGE_PATH_COMUN":
            return {
                ...state, currentPath: payload
            };
            break;
        case "LOAD_CONTENTS_COMUN":
            return {
                ...state, contents: payload
            }
            break;
    }
}