import selectPath from "../selectPath";

export default (data) => new Promise((resolve, reject) => {
    const routes = selectPath();
    let headers = new Headers({
        "Accept": "application/json",
        "Content-Type": "application/json"
    });

    let request = new Request(routes.users.base, {
        method: "POST",
        headers,
        credentials: "include",
        body: JSON.stringify(data)
    });

    fetch(request)
        .then(res => res.json())
        .then(data => resolve(data))
        .then(err => resolve(err));
});