import GetGetData from "../services/Users/GetGetData";
import PostLogin from "../services/Users/PostLogin";
import GetLogout from "../services/Users/GetLogout";
import Get from "../services/Users/Get";
import Post from "../services/Users/Post";
import Put from "../services/Users/Put";

export default () => {

    const login = data => PostLogin(data);

    const getClients = () => Get();

    const getData = () => GetGetData();

    const getLogout = () => GetLogout();

    const createClient = data => Post(data);

    const updateClient = data => Put(data);

    return {
        login,
        getData,
        getLogout,
        createClient,
        getClients,
        updateClient
    };
}