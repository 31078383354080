//#region  IMPORTS
import React, { useContext, useEffect, useRef, useLayoutEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ExplorerScreenUI from "./ExplorerScreenUI";
import UserContext from "../../contexts/UserContext/UserContext";
import FileContext from "../../contexts/FileContext/FileContext";
import MessageService from "../../services/MessageService";
import { useShowView } from "../../hooks/useShowView";
import CheckResultNavFolder from "../../components/GeneralFunctions/CheckResultNavFolder";
//#endregion

const ExplorerScreen = () => {
    //#region VARIABLES
    let navigate = useNavigate();
    let messageService = new MessageService();
    const { idUser } = useParams();
    const isUsers = useLocation()?.state?.isUsers || undefined
    const isFolder = useLocation()?.state?.isFolder || undefined
    //#endregion

    //#region USE REF
    const createFolderModalRef = useRef();
    const deleteElementModalRef = useRef();
    const uploadModalRef = useRef();
    //#endregion

    //#region USE CONTEXT
    const userContext = useContext(UserContext);
    const fileContext = useContext(FileContext);
    //#endregion

    //#region CUSTOM HOOKS
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: false })
    //#endregion

    //#region USE STATE
    const [deleteTarget, setDeleteTarget] = useState({ data: {}, isComun: false });
    const [folderNameForm, setFolderNameForm] = useState({ Name: "" });
    const [userData, setUserData] = useState({});
    const [comunFolder, setComunFolder] = useState([])
    const [contentsComun, setContentsComun] = useState([])
    const [search, setSearch] = useState("")
    const [isComun, setIsComun] = useState(false)
    //#endregion

    //#region USE LAYOUT AND EFFECTUSE EFFECT
    useLayoutEffect(() => {
        (async () => {
            let data = userContext.state?.sessionData?.Id ? userContext.state.sessionData : await userContext.getSessionData()
            !data.Id && navigate("/")
        })()
    }, [])

    useEffect(() => {
        (async () => {
            let data = !userContext.state.sessionData?.Id ? await userContext.getSessionData() : userContext.state.sessionData
            setUserData(data);
            let clientCode = !idUser ? (data.ClientCode + '_' + data.Name) : idUser
            let resComun = await fileContext.navigateRoute("/WEB", true, data.Id)
            setComunFolder(resComun?.filter(el => el.name === "Común"))
            let result = await fileContext.enterFolder(clientCode, false, data.Id);
            CheckResultNavFolder(result)
            window.addEventListener("popstate", resetValues)
            return () => {
                window.removeEventListener("popstate", resetValues)
            }
        })();
    }, []);

    //#endregion

    //#region FUNCTIONS
    const resetValues = () => window.location.reload()

    const clickElement = async (data, isComun = false) => {
        let result = await fileContext.enterFolder(data.name, isComun, userData.Id);
        CheckResultNavFolder(result)
    }

    const handleEventBack = async (element, isComun = false) => {
        if (element !== '/') {
            let arrPath = isComun ? fileContext.stateComun.currentPath.split("/").filter(e => e !== "") : fileContext.state.currentPath.split("/").filter(e => e !== "")
            let indexPath = arrPath.findIndex(el => el === element)
            let newArrPath = arrPath.filter((el, index) => index <= indexPath)
            let result = await fileContext?.navigateRoute('/' + newArrPath.join('/') + '/', isComun, userData.Id)
            CheckResultNavFolder(result)
        } else {
            if (isComun) {
                let resComun = await fileContext.navigateRoute('/WEB', isComun, userData.Id);
                setComunFolder(resComun?.filter(el => el.name === "Común"))
            }
            let clientCode = !idUser ? userData.ClientCode : idUser
            let result = await fileContext.navigateRoute('/' + clientCode + '/');
            CheckResultNavFolder(result)
        }
    }

    const handleDownloadFile = (e, data, isComun = false) => {
        e.stopPropagation();
        console.log(e, data, isComun)
        fileContext.downloadFile(data.name, isComun)
    }

    const goBack = (isComun = false) => fileContext.goOneFolderBack(isComun, userData.Id);

    const getCreateFolderFormInitialValues = () => setFolderNameForm({ Name: "" });

    const submitFormCreateFolder = async (values, { resetForm }) => {
        values.CurrentPath = isComun ? fileContext.stateComun.currentPath : fileContext.state.currentPath;
        let result = await fileContext.createNewFolder(values, isComun);
        if (result?.type !== "error") {
            setIsComun(false)
            resetForm(getCreateFolderFormInitialValues())
            createFolderModalRef.current.hide();
            return;
        }

        if (result.code === 4) {
            messageService.error("Ya existe");
        } else if (result.code === 3) {
            messageService.error("Sin permisos");
        } else {
            messageService.error("Error interno");
        }
    }

    const submitFormUploadFiles = async (evt) => {
        evt.preventDefault();
        const files = evt.target.files.files;
        let formData = new FormData();
        for (let index = 0; index < files.length; index++) {
            formData.append('userUpload', files[index]);
        }
        let result = await fileContext.uploadElements(formData, isComun);
        setIsComun(false)
        if (result.type === "success") {
            uploadModalRef.current.hide();
        } else if (result.code === 10) {
            messageService.error("Error interno");
        } else if (result.code === 11 || result.code === 12) {
            messageService.error("Los archivos no cumplen con los requisitos");
        } else if (result.code === 3) {
            messageService.error("Sin permisos");
        } else if (result.code === 13) {
            messageService.error("Quota excedida");
        } else {
            messageService.error("Error interno");
        }
    }

    const deleteElement = async () => {
        let path = `${deleteTarget.isComun ? fileContext.stateComun.currentPath : fileContext.state.currentPath}${deleteTarget.data.name}`
        let result = await fileContext.deleteElement({
            CurrentPath: path,
            Directory: deleteTarget.data.directory
        }, deleteTarget.isComun);
        if (result.type != "error") {
            setDeleteTarget({});
            deleteElementModalRef.current.hide();
            return;
        }

        if (result.code === 5) {
            messageService.error("El archivo o carpeta existe");
        } else if (result.code === 3) {
            messageService.error("Sin Permisos");
        } else {
            messageService.error("Error interno");
        }
    }

    const doFilterComun = (item) => {
        if (!search.trim()) {
            return item
        } else if (item.name && item.name.toLowerCase().includes(search.toLocaleLowerCase())) {
            return item
        }
    }

    const doFilter = (item) => {
        if (!search.trim()) {
            return item
        } else if (item.name && item.name.toLowerCase().includes(search.toLocaleLowerCase())) {
            return item
        }
    }

    const getBreadcumb = (isComun = false) => fileContext.getBreadcumb(isComun)
    //#endregion

    return <ExplorerScreenUI
        clickElement={clickElement}
        goBack={goBack}
        userData={userData}
        contents={fileContext.state.contents}
        contentsComun={fileContext.stateComun.contents}
        currentPath={fileContext.state.currentPath}
        currentPathComun={fileContext.stateComun.currentPath}
        createFolderModalRef={createFolderModalRef}
        deleteElementModalRef={deleteElementModalRef}
        uploadModalRef={uploadModalRef}
        submitFormCreateFolder={submitFormCreateFolder}
        getCreateFolderFormInitialValues={getCreateFolderFormInitialValues}
        deleteTarget={deleteTarget}
        setDeleteTarget={setDeleteTarget}
        deleteElement={deleteElement}
        submitFormUploadFiles={submitFormUploadFiles}
        folderNameForm={folderNameForm}
        comun={{
            folder: comunFolder,
            setFolder: setComunFolder,
            contentFolder: contentsComun,
            setContentFolder: setContentsComun
        }}
        search={{
            get: search,
            set: setSearch
        }}
        doFilter={doFilter}
        doFilterComun={doFilterComun}
        paramsInit={{
            isFolder: isFolder,
            isUsers: isUsers
        }}
        handleDownloadFile={handleDownloadFile}
        setIsComun={setIsComun}
        breadCumb={getBreadcumb}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        currentLevel={fileContext.getCurrentLevel()}
        currentLevelComun={fileContext.getCurrentLevel(true)}
        handleBreadCumb={handleEventBack}
    />;
}

export default ExplorerScreen