import React from "react";
import useFile from "../../hooks/useFile";
import * as Yup from "yup";
import CreateFolderFormUI from "./CreateFolderFormUI";

export default ({ idSubmitButton, submitFormCreateFolder, folderNameForm }) => {
    const CreateFolderSchema = Yup.object().shape({
        Name: Yup.string().required("Campo obligatorio")
    });

    return <CreateFolderFormUI
        submitForm={submitFormCreateFolder}
        idSubmitButton={idSubmitButton}
        CreateFolderSchema={CreateFolderSchema}
        folderNameForm={folderNameForm}
    />
}