import selectPath from "../selectPath";

export default (folderPath, filename) => new Promise((resolve, reject) => {
    const routes = selectPath();
    let headers = new Headers({
        "Content-Type": "application/json"
    });

    let request = new Request(routes.files.download, {
        method: "POST",
        headers,
        body: JSON.stringify({ CurrentPath: folderPath + filename }),
        credentials: "include"
    });

    fetch(request)
        .then(async res => {
            if (res.ok) {
                resolve(await res.blob());
            }
        })
        .catch(err => resolve(err));
});