import Folder from "../Folder";

export default ({ visible, onClickBack }) => (
    visible && (
        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
            <div onClick={onClickBack}>
                <Folder />
            </div>
        </div>
    )
)