import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../contexts/UserContext/UserContext";
import LoginScreenUI from "./LoginScreenUI";
import * as Yup from "yup";
import useUser from "../../hooks/useUser";
import MessageService from "../../services/MessageService";

export default () => {
    let navigate = useNavigate();
    const userContext = useContext(UserContext);
    let messaageService = new MessageService();
    const { login } = useUser();

    useEffect(() => { getSessionAndNavigate() }, []);

    const getSessionAndNavigate = async () => {
        let data = userContext.state.sessionData?.Id ? userContext.state.sessionData : await userContext.getSessionData()
        console.log(data)
        data.Id && navigate("/main", { state: { isUsers: true } })
    }

    const LoginSchema = Yup.object().shape({
        Email: Yup.string().email("Debes introducir una dirección de correo válida"),
        Password: Yup.string().min(1, "Campo obligatorio")
    });

    const submitForm = async (values) => {
        let result = await login(values)
        if (result.type != "error") {
            userContext.setSessionData(result);
            messaageService.success("Sesión inciada")
            if (result.IdRole === 1) {
                navigate("/main", { state: { isUsers: true } });
            } else {
                navigate("/explorer");
            }
        } else {
            messaageService.error("Credenciales inválidas");
        }
    }

    return <LoginScreenUI
        submitForm={submitForm}
        LoginSchema={LoginSchema}
    />;
}