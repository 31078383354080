export default (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "GET_USER":
            return {
                ...state, sessionData: payload
            };
            break;
        case "GET_CLIENTS":
            return {
                ...state, clients: payload
            };
            break;
    }
}